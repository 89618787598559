import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import tiktok from "../assets/tiktok.png";
import twitter from "../assets/twitter.png";
import logo from "../assets/logo4.png";
import { Link } from "react-router-dom";
import IntersectionObserverImage from "./IntersectionObserverImage";

export default function Footer() {
  return (
    <div class="bg-black">
      <div>
        <div class="flex justify-around flex-col md:flex-row">
          <div className="flex items-center md:mx-0 mx-auto">
            <Link to="/" className="logo ">
              {/* <img src={logo} id='logo'></img> */}
              <IntersectionObserverImage url={logo} id="logo" />
            </Link>
          </div>

          <div class="flex w-[230px] justify-between items-center mt-4 md:mx-0 mx-auto">
            <a href="https://www.instagram.com/eventverstr/" target="_blank">
              <InstagramIcon fontSize="large" style={{ color: "lightgray" }} />{" "}
            </a>
            <a
              href="https://www.youtube.com/channel/UCJleTZur9GeGgXxYJVBA3mg"
              target="_blank"
            >
              <YouTubeIcon fontSize="large" style={{ color: "lightgray" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/eventvers/"
              target="_blank"
            >
              <LinkedInIcon fontSize="large" style={{ color: "lightgray" }} />
            </a>
            <a
              href="https://www.tiktok.com/@eventvers?lang=tr-TR"
              target="_blank"
            >
              <img
                height="25"
                style={{ paddingTop: 5, borderRadius: 5, width: 25 }}
                src={tiktok}
              ></img>
            </a>
            <a href="https://twitter.com/eventverstr?s=21" target="_blank">
              <img
                height="5"
                style={{
                  paddingTop: 5,
                  borderRadius: 5,
                  paddingLeft: 5,
                  width: 30,
                }}
                src={twitter}
              ></img>
            </a>
          </div>
          <div className="w-[200px] flex justify-center md:mx-0 mx-auto">
            <div className="flex flex-col mt-8 text-gray-300 text-center">
              <a href="/" className=" leading-none mt-2 border-b-2 border-transparent hover:border-white transition-colors duration-300">Ana Sayfa</a>
              <a href="/#/events" className=" leading-none mt-2 border-b-2 border-transparent hover:border-white transition-colors duration-300">Etkinlikler</a>
              <a href="/#/contact" className=" leading-none mt-2 border-b-2 border-transparent hover:border-white transition-colors duration-300">İletişim</a>
              <a href="/#/login" className=" leading-none mt-2 border-b-2 border-transparent hover:border-white transition-colors duration-300">Giriş yap</a>
              <a href="/#/register" className=" leading-none mt-2 border-b-2 border-transparent hover:border-white transition-colors duration-300">Kayıt ol</a>
            </div>
          </div>
        </div>
        <div class="text-[#2e2e2e] text-center py-4 mt-8" style={{borderTop:"1px solid #2e2e2e"}}>
          © 2024 EVENTVERS | Tüm Hakları Saklıdır.
        </div>
      </div>
    </div>
  );
}
