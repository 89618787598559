export default function EventLoading() {
  return (
    <div class=" max-w-sm w-[300px] mx-auto mt-[30px]">
      <div class="animate-pulse flex flex-col space-y-4">
        <div class="rounded-2xl bg-gray-300 h-[320px] w-[300px]"></div>
        <div class="flex-1 space-y-4 py-1 w-[250px] ml-2">
          <div class="h-4 bg-gray-300 rounded w-3/4"></div>
          <div class="space-y-2">
            <div class="h-4 bg-gray-300 rounded"></div>
            <div class="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
        </div>
        <div class="h-9 mt-2 bg-gray-300 rounded-lg w-[270px]"></div>
      </div>
    </div>
  );
}
